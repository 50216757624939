import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Moment from 'react-moment';
import NewsAuthor from '../components/news/NewsAuthor';
import NewsPostPageWrapper from '../styles/news/NewsPostStyles';
import Layout from './layout';

// TODO add next and previous post links

const newsPost = ({ path, data }) => {
  const {
    title,
    subtitle,
    slug,
    type,
    date,
  } = data.markdownRemark.frontmatter;

  // ? set SEO meta data depending on post type
  let seo;
  if (type === 'newsPost') {
    seo = {
      page: `${type}`,
      title: `${title}`,
      description: `${data.markdownRemark.excerpt}`,
      url: `https://about.9ualia.com/${slug}`,
      breadcrumbs: [
        {
          name: 'News',
          path: '/news',
        },
        {
          name: `${title}`,
          path: `/news/${slug}`,
        },
      ],
    };
  } else if (type === 'service') {
    seo = {
      page: `${type}`,
      title: `${title}`,
      description: `${data.markdownRemark.excerpt}`,
      url: `https://about.9ualia.com/${slug}`,
      breadcrumbs: [
        {
          name: 'Services',
          path: '/services',
        },
        {
          name: `${title}`,
          path: `/services/${slug}`,
        },
      ],
    };
  }

  return (
    <Layout seo={seo} path={path} style={{ textAlign: 'left' }}>
      <NewsPostPageWrapper>
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
        <p className="published">
          <Moment date={date} format="YYYY年MM月DD日" />
        </p>
        <NewsAuthor />

        <article
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
        />

      </NewsPostPageWrapper>
    </Layout>
  );
};

newsPost.propTypes = {
  path: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default newsPost;

export const NEWS_POST_QUERY = graphql`
  query($slug: String!, $imgUrl: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      html
      excerpt(pruneLength: 370)
      timeToRead
      frontmatter {
        type
        title
        slug
        subtitle
        imageTitle
        imageAlt
        date
        tags
      }
    }

    file(relativePath: { eq: $imgUrl }) {
      publicURL # used for SEO
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
